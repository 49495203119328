<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p>根据移民政策部（Migration Policy Ministry）的所发布的新数据显示，从今年年初到10月底，共签发了747张。希腊黄金签证发放率正在上升，仅上个月就发放了193份黄金签证，而今年前三季度一共仅发放了554份。可见，向房地产投资者发放居留证件的黄金签证项目审批效率大幅提升。</p>
        <p>也再一次说明了，希腊对于海外投资者一直保持着积极欢迎的态度；而海外投资者对希腊房地产的投资热情也在持续增长。根据全球第四大评级机构DBRS的数据，希腊房价在2018-2019年期间累计上涨了9.2%，2020年尽管受旅行限制和其他针对新冠病毒的措施影响，涨幅仍达到4.6%，虽有所放缓，但市场表现呈积极态势。在此，我们建议有购置希腊房产需求的客户，尽快打破观望，早日购入自己心仪的房产；
        </p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news14",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:14,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>